<template>
  <section class="path-user">
    <div class="layout-container user-activation-page">
      <main role="main" class="main-content-wrapper">
        <div class="container">
          <div class="login-wrapper">
            <AuthHeader />

            <div v-if="registrationVerified" class="alert alert-success alert-custom" key="success">
              <div class="checkmark-wrapper success">
                <i class="glyphicon glyphicon-ok"></i>
              </div>
              <h3 class="success">Registration Successful</h3>
              <p>
                Your registration has been successfully verified. <br />
                You are now able to login to the {{ $store.state.config.siteName }}.
              </p>
              <p>
                <router-link to="login" class="btn-register">Go To Login</router-link>
              </p>
            </div>
            <div v-if="userAlreadyActive" class="alert alert-success alert-custom" key="already_active">
              <h3 class="success">User account already activated</h3>
              <p>Please go to the login page to login.</p>
              <p>
                <router-link to="login" class="btn-register">Go To Login</router-link>
              </p>
            </div>
            <div v-if="registrationVerifiedError" class="alert alert-danger alert-custom" key="error">
              <h3 class="danger">Verfication Error</h3>
              <p>
                Error occurred, please refresh the page to try again. If problems persists please call {{ $store.state.config.supportTel }} for
                further assistance.
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  </section>
</template>

<script>
import AuthHeader from '@/components/generic/AuthHeader.vue';
import * as customFunctions from '@/helpers/functions.js';
export default {
  components: {
    AuthHeader,
  },
  data() {
    return {
      registrationVerified: false,
      registrationVerifiedError: false,
      userAlreadyActive: false,
    };
  },
  created() {
    this.verifyCode();
    // console.log(customFunctions.findGetParameter('code'));
    // const code = customFunctions.findGetParameter('code');
  },
  methods: {
    verifyCode() {
      const code = customFunctions.findGetParameter('verify_url');
      if (code) {
        this.$store.commit('setTransLoader', true);
        this.$store
          .dispatch('ACTIVATE_ACCOUNT', code)
          .then((res) => {
            if (res?.message == 'already_verified') {
              this.registrationVerified = false;
              this.registrationVerifiedError = false;
              this.userAlreadyActive = true;
            } else if (res?.message == 'verified') {
              this.registrationVerified = true;
              this.registrationVerifiedError = false;
              this.userAlreadyActive = false;
            } else {
              this.verificationError();
            }
          })
          .catch((e) => {
            this.verificationError();
          })
          .finally(() => this.$store.commit('setTransLoader', false));
      } else {
        this.$router.push({ name: 'Login' });
      }
    },
    verificationError() {
      this.registrationVerified = false;
      this.userAlreadyActive = false;
      this.registrationVerifiedError = true;
    },
  },
};
</script>

<style lang="scss">
.user-activation-page {
  .btn-register {
    margin-top: 40px;
    margin-left: 10px;
    line-height: 1.5rem;
    text-align: center;
    box-shadow: none;
    font-size: 1.2rem;
  }
}
</style>